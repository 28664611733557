.blink-class {
  animation: blink 1s linear infinite;
}

@keyframes blink {
  50% { opacity: 0; }
}

.game-end-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.game-end-content {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.country-label {
  background: rgba(0, 0, 0, 0.8);
  border: none;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
}

.winning {
  color: #4CAF50;
  font-weight: bold;
}

.animate-fade-in {
  animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
