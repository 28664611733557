.map-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin: 0 auto;
    position: relative;
    background-color: #282c34;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    outline: none !important;
    z-index: 1;
  }

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.blink-class {
  animation: blink 1s linear infinite;
}

.label {
  width: 100px;
  background-color: rgba(255, 255, 255, 0.5);
  text-align: center;
  line-height: 40px;
  border: 1px solid #ddd;
}

.country-label {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #222;
  border-radius: 3px;
  color: #333;
  font-size: 12px;
  padding: 2px 6px;
}

/* Multiplayer specific styles */
.scores-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.player-score {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.player-score.winning {
  background: linear-gradient(45deg, #4CAF50, #45a049);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.vs {
  font-weight: bold;
  color: #fff;
  margin: 0 1rem;
}

.round-info {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.winning {
  animation: pulse 1s infinite;
}

/* Remove outline on country click */
.leaflet-interactive {
  outline: none !important;
}

.leaflet-container {
  outline: none !important;
  z-index: 1 !important;
}

/* Game end overlay */
.game-end-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.game-end-content {
  background: linear-gradient(135deg, #6366f1 0%, #3b82f6 50%, #06b6d4 100%);
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  text-align: center;
  max-width: 28rem;
  width: 90%;
  margin: 0 1rem;
}

.audio-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
}

.audio-player {
  width: 300px;
  height: 40px;
  margin-bottom: 10px;
}

.play-again-btn {
  padding: 8px 16px;
  background: #4a5568;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.2s;
}

.play-again-btn:hover {
  background: #2d3748;
}

.play-again-btn.disabled {
  background: #718096;
  cursor: not-allowed;
}

.round-end-message {
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  margin: 10px 0;
  text-align: center;
  font-size: 1.2em;
  animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.winning {
  color: #4CAF50;
  font-weight: bold;
}
